@-webkit-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
    }
}

@-moz-keyframes bounceOutLeft {
    0% {
        -moz-transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateX(-2000px);
    }
}

@-o-keyframes bounceOutLeft {
    0% {
        -o-transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        -o-transform: translateX(20px);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateX(-2000px);
    }
}

@keyframes bounceOutLeft {
    0% {
        transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        transform: translateX(20px);
    }
    
    100% {
        opacity: 0;
        transform: translateX(-2000px);
    }
}

.bounceOutLeft {
    .animation-name(bounceOutLeft);
}