@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    .animation-name(fadeInUp);
}