@-webkit-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }
    
    100% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}

@-moz-keyframes rotateInUpRight {
    0% {
        -moz-transform-origin: right bottom;
        -moz-transform: rotate(-90deg);
        opacity: 0;
    }
    
    100% {
        -moz-transform-origin: right bottom;
        -moz-transform: rotate(0);
        opacity: 1;
    }
}

@-o-keyframes rotateInUpRight {
    0% {
        -o-transform-origin: right bottom;
        -o-transform: rotate(-90deg);
        opacity: 0;
    }
    
    100% {
        -o-transform-origin: right bottom;
        -o-transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpRight {
    0% {
        transform-origin: right bottom;
        transform: rotate(-90deg);
        opacity: 0;
    }
    
    100% {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateInUpRight {
    .animation-name(rotateInUpRight);
}