@-webkit-keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

@-o-keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

.fadeIn {
    .animation-name(fadeIn);
}