// Footer
#faq-theme {
	position: relative; 
	z-index: 1;
	margin-bottom: -80px;
	.faq {
		padding: 40px 85px 70px;
		background-color: @brand-primary;
		box-shadow: 0px 32px 42px 0px rgba(0, 0, 0, 0.17);
		color: #fff;
		@media (max-width: @screen-xs-max) {
			padding: 30px 15px 45px;
		}
		h2 {
			margin: 0 0 40px;
			color: #fff;
			font-weight: 300;
			text-align: center;
			strong {
				color: #fff;
				font-weight: 600;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			@media (min-width: @screen-sm-min) {
				-webkit-column-count: 2;
				-moz-column-count: 2;
				column-count: 2;
			}
			li {
				display: inline-block;
				position: relative;
				padding-left: 15px;
				font-family: @ff-headings;
				width: 100%;
				&:before {
					content: "\f105";
					position: absolute;
					top: 0;
					left: 0;
					font-family: 'FontAwesome';
				}
				a {
					display: block;
					color: #fff;
				}
			}
		}
	}
}

#footer {
	position: relative;
	padding-top: 150px;
	padding-bottom: 70px;
	background-color: @brand-gray;
	color: #fff;
	font-size: 0.88rem;
	line-height: 1.71em;
	@media (max-width: @screen-sm-max) {
		padding-bottom: 0;
	}
	p {
		margin: 0 0 40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.sitename {
		text-transform: uppercase;
	}
	a {
		color: #fff;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.logos {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 30px;
		background-color: #fff;
		font-size: 0;
		@media (max-width: @screen-sm-max) {
			position: relative;
			margin-top: 70px;
			bottom: auto;
			right: auto;
			text-align: center;
		}
		a {
			display: inline-block;
			vertical-align: middle;
			@media (max-width: @screen-xs-max) {
				display: block;
				img {
					margin: 0 auto;
				}
			}
			& + a {
				margin-left: 30px;
				@media (max-width: @screen-xs-max) {
					margin-top: 15px;
					margin-left: 0;
				}
			}
		}
	}
}