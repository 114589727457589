@-webkit-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOutUpLeft {
    0% {
        -moz-transform-origin: left bottom;
        -moz-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -moz-transform-origin: left bottom;
        -moz-transform: rotate(-90deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOutUpLeft {
    0% {
        -o-transform-origin: left bottom;
        -o-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -o-transform-origin: left bottom;
        -o-transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpLeft {
    0% {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -transform-origin: left bottom;
        -transform: rotate(-90deg);
        opacity: 0;
    }
}

.rotateOutUpLeft {
    .animation-name(rotateOutUpLeft);
}