@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -moz-transform: translateY(-2000px);
    }
    
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -o-transform: translateY(-2000px);
    }
    
    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDownBig {
    .animation-name(fadeInDownBig);
}