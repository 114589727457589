// Header
#header {
    height: 115px;
    background-color: #f3f3f3;
    @media (max-width: @screen-md-max) {
        height: 55px;
    }
    @media (max-width: @screen-xs-max) {
        height: 110px;
    }
    .fixed-nav {
        position: absolute;
        z-index: 200;
        top: 0;
        left: 0;
        width: 100%;
        @media (max-width: @screen-md-max) {
            position: fixed;
            top: 0 !important;
        }
        > .container {
            position: relative;
            margin-top: 60px;
            background-color: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
            @media (max-width: @screen-md-max) {
                margin-top: 0;
            }
        }
    }

    .logo {
        float: left;
        padding: 15px 10px;
        img {
            width: 100px;
        }
    }

    .mainNav {
        .clearfix;
        > ul {
            float: right;
            margin: 0;
            margin-right: -15px;
            padding: 0;
            list-style: 0;
            @media (max-width: @screen-md-max) {
                float: none;
                margin: 0;
            }
            > li {
                float: left;
                @media (max-width: @screen-md-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    position: relative;
                    padding: 45px 16px;
                    background-color: transparent;
                    color: @brand-primary;
                    font-family: @ff-headings;
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 1.25em;
                    text-transform: uppercase;
                    .transition(all .2s);
                    @media (max-width: @screen-md-max) {
                        padding-top: 25px;
                        padding-bottom: 25px;
                        color: #fff;
                    }
                    .caret {
                        display: none;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        height: 10px;
                        width: 100%;
                        background-image: url(../img/arrow.png);
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        opacity: 0;
                        .transition(all .2s);
                        @media (max-width: @screen-md-max) {
                            display: none;
                        }
                    }
                }
                &.active, &.active-trail {
                    > a, > .nolink {
                        background-color: @brand-primary;
                        color: #fff;
                        &:after {
                            opacity: 1;
                        }
                        @media (max-width: @screen-md-max) {
                            background-color: @brand-gray;
                        }
                    }
                }
                &:hover, &.open {
                    > a, > .nolink {
                        background-color: fade(@brand-primary, 10%);
                        color: @brand-primary;
                        &:after {
                            opacity: .1;
                        }
                        @media (max-width: @screen-md-max) {
                            background-color: @brand-gray;
                            color: #fff;
                        }
                    }
                }
                .dropdown-menu {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    float: none;
                    min-width: 250px;
                    padding: 25px 0;
                    margin: 0;
                    list-style: none;
                    background-color: #fff;
                    border: 0;
                    border-radius: 5px;
                    text-align: center;
                    -webkit-box-shadow: 0px 9px 23px 0px rgba(0, 0, 0, 0.46);
                    -moz-box-shadow: 0px 9px 23px 0px rgba(0, 0, 0, 0.46);
                    box-shadow: 0px 9px 23px 0px rgba(0, 0, 0, 0.46);
                    @media (min-width: @screen-lg-min) {
                        -webkit-transform: translate(-50%,0);
                        -moz-transform: translate(-50%,0);
                        -ms-transform: translate(-50%,0);
                        -o-transform: translate(-50%,0);
                        transform: translate(-50%,0);
                    }
                    @media (max-width: @screen-md-max) {
                        position: relative;
                        top: auto;
                        left: auto;
                        min-width: 0;
                        background-color: fade(#fff, 75%);
                        border-radius: 0;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                    > li {
                        > a, > .nolink {
                            display: block;
                            padding: 10px 15px;
                            background-color: transparent;
                            color: @brand-primary;
                            font-family: @ff-headings;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.25em;
                            white-space: normal;
                            .transition(all .2s);
                            .caret {
                                display: none;
                            }
                        }
                        &.active, &.active-trail {
                            > a, > .nolink {
                                background-color: @brand-primary;
                                color: #fff;
                                @media (max-width: @screen-md-max) {
                                    background-color: fade(@brand-primary, 10%);
                                    color: @brand-primary;
                                }
                            }
                        }
                        &:hover, &.open {
                            > a, > .nolink {
                                background-color: fade(@brand-primary, 10%);
                                color: @brand-primary;
                            }
                        }
                    }
                }
            }
        }
    }

    .topNav {
        position: absolute;
        top: -60px;
        right: 15px;
        @media (max-width: @screen-md-max) {
            position: relative;
            top: auto;
            right: auto;
            margin-top: 60px;
        }
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
                float: left;
                @media (max-width: @screen-md-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    padding: 20px 0;
                    background-color: transparent;
                    color: #575656;
                    font-family: @ff-headings;
                    font-size: 0.88rem;
                    font-weight: 600;
                    line-height: 1.43em;
                    text-transform: uppercase;
                    .transition(all .2s);
                    @media (max-width: @screen-md-max) {
                        color: #fff;
                    }
                    .caret {
                        display: none;
                    }
                }
                &:hover, &.open, &.active, &.active-trail {
                    > a, > .nolink {
                        color: @brand-primary;
                        @media (max-width: @screen-md-max) {
                            background-color: @brand-gray;
                            color: #fff;
                        }
                    }
                }
                & + li {
                    margin-left: 15px;
                    @media (max-width: @screen-md-max) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &.scrolled {
        .fixed-nav {
            position: fixed;
            top: -60px;
        }
    }
}

.trigger-menu {
    position: relative;
    z-index: 1;
    float: right;
    padding: 15px 10px;
    svg {
    	display: block;
        fill: @brand-primary !important;
    	rect {
    		-webkit-transform-origin: left center;
    		-moz-transform-origin: left center;
    		-ms-transform-origin: left center;
    		-o-transform-origin: left center;
    		transform-origin: left center;
    		-webkit-transform: rotate(0);
    		-moz-transform: rotate(0);
    		-ms-transform: rotate(0);
    		-o-transform: rotate(0);
    		transform: rotate(0);
    		.transition(all .2s);
    	}
    }
    &.open {
    	svg {
    		rect {
                fill: #fff !important;
    			&:nth-child(1) {
    				-webkit-transform: rotate(45deg);
    				-moz-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				-o-transform: rotate(45deg);
    				transform: rotate(45deg);
    				x: 3px;
    			}
    			&:nth-child(2) {
    				width: 0%;
    				opacity: 0;
    			}
    			&:nth-child(3) {
    				-webkit-transform: rotate-(45deg);
    				-moz-transform: rotate(-45deg);
    				-ms-transform: rotate(-45deg);
    				-o-transform: rotate(-45deg);
    				transform: rotate(-45deg);
    				x: 3px;
    			}
    		}
    	}
    }
}

@media (max-width: @screen-md-max) {
    nav#nav {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 60px 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: fade(@brand-primary,90%);
        overflow-x: auto;
        overflow-y: scroll;
        .transition(all .35s);
    }
}

body.open-menu {
    @media (max-width: @screen-md-max) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}

#banner {
    position: relative;
    height: 400px;
    background-color: #000;
    text-align: center;
    @media (max-width: @screen-xs-max) {
        height: 270px;
    }
    .content {
        .v-align;
        z-index: 5;
        width: 100%;
    }
    .contextual-links-region {
        position: static;
    }
    &:not(.banner-fiche) {
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: -moz-linear-gradient(top,  rgba(17,16,10,1) 0%, rgba(17,16,10,0) 100%);
            background: -webkit-linear-gradient(top,  rgba(17,16,10,1) 0%,rgba(17,16,10,0) 100%);
            background: linear-gradient(to bottom,  rgba(17,16,10,1) 0%,rgba(17,16,10,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11100a', endColorstr='#0011100a',GradientType=0 );
            opacity: .5;
        }
        .bg {
            opacity: .8;
        }
    }
    &.banner-fiche {
        height: 550px;
        background-color: #d9d9d9;
        @media (max-width: @screen-xs-max) {
            height: 300px;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 25%;
            left: 0;
            background: -moz-linear-gradient(top,  rgba(3,41,65,1) 0%, rgba(3,41,65,0) 100%);
            background: -webkit-linear-gradient(top,  rgba(3,41,65,1) 0%,rgba(3,41,65,0) 100%);
            background: linear-gradient(to bottom,  rgba(3,41,65,1) 0%,rgba(3,41,65,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#032941', endColorstr='#00032941',GradientType=0 );
            opacity: .8;
        }
        .title {
            margin-left: auto;
            margin-right: auto;
            padding: 65px 15px 90px;
            max-width: 540px;
            border: 5px solid #fff;
            @media (max-width: @screen-xs-max) {
                padding: 60px 15px;
            }
            h1 {
                margin: 0 0 -10px;
            }
            p {
                margin: 0;
                color: #fff;
                font-family: @ff-headings;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

body.page-actualites, body.node-type-actualites, body[class*="page-extranet"] {
    #banner {
        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: .8;
        }
    }
}
body.page-actualites, body.node-type-actualites {
    #banner {
        &:before {
            background-image: url(../img/banner-actualites.jpg);
        }
    }
}
body[class*="page-extranet"] {
    #banner {
        &:before {
            background-image: url(../img/banner-extranet.jpg);
        }
    }
}