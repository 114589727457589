@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@-moz-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@-o-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

.fadeOut {
    .animation-name(fadeOut);
}