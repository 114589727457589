@-webkit-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
    }
}

@-moz-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateX(-2000px);
    }
}

@-o-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -o-transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateX(-2000px);
    }
}

@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        transform: translateX(-2000px);
    }
}

.fadeOutLeftBig {
    .animation-name(fadeOutLeftBig);
}