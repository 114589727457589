@-webkit-keyframes tada {
    0% {-webkit-transform: scale(1);}    
    10%, 20% {-webkit-transform: scale(0.9) rotate(-3deg);}
    30%, 50%, 70%, 90% {-webkit-transform: scale(1.1) rotate(3deg);}
    40%, 60%, 80% {-webkit-transform: scale(1.1) rotate(-3deg);}
    100% {-webkit-transform: scale(1) rotate(0);}
}

@-moz-keyframes tada {
    0% {-moz-transform: scale(1);}    
    10%, 20% {-moz-transform: scale(0.9) rotate(-3deg);}
    30%, 50%, 70%, 90% {-moz-transform: scale(1.1) rotate(3deg);}
    40%, 60%, 80% {-moz-transform: scale(1.1) rotate(-3deg);}
    100% {-moz-transform: scale(1) rotate(0);}
}

@-o-keyframes tada {
    0% {-o-transform: scale(1);}    
    10%, 20% {-o-transform: scale(0.9) rotate(-3deg);}
    30%, 50%, 70%, 90% {-o-transform: scale(1.1) rotate(3deg);}
    40%, 60%, 80% {-o-transform: scale(1.1) rotate(-3deg);}
    100% {-o-transform: scale(1) rotate(0);}
}

@keyframes tada {
    0% {transform: scale(1);}    
    10%, 20% {transform: scale(0.9) rotate(-3deg);}
    30%, 50%, 70%, 90% {transform: scale(1.1) rotate(3deg);}
    40%, 60%, 80% {transform: scale(1.1) rotate(-3deg);}
    100% {transform: scale(1) rotate(0);}
}

.tada {
    .animation-name(tada);
}