@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }
}

@-moz-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }
}

@-o-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -o-transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateY(20px);
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

.fadeOutDown {
    .animation-name(fadeOutDown);
}