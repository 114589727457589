// Fiche
.backToList {
	padding-top: 40px;
}

#intro-fiche {
	padding-top: 40px;
	padding-bottom: 30px;
}

#photos {
	padding-top: 155px;
	padding-bottom: 110px;
	overflow: hidden;
	@media (max-width: @screen-md-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@media (max-width: @screen-sm-max) {
		padding-bottom: 0;
	}
	.slide {
		background-color: @brand-primary;
		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			width: 9999px;
			background-color: @brand-primary;
		}
		&:before {
			left: 100%;
		}
		&:after {
			right: 100%;
		}
	}

	.img-wrapper {
		box-shadow: 0px 12px 42px 0px rgba(0, 0, 0, 0.1);
		img {
			margin-top: -125px;
			margin-bottom: -80px;
			@media (max-width: @screen-md-max) {
				margin-top: -30px;
				margin-bottom: -30px;
			}
			@media (max-width: @screen-sm-max) {
				margin-bottom: 0;
			}
		}
	}
	.content {
		padding-top: 40px;
		padding-bottom: 40px;
		padding-left: 30px;
		color: #fff;
		font-size: 0.88rem;
		line-height: 1.79em;
		@media (max-width: @screen-sm-max) {
			padding-left: 0;
		}
		p {
			margin: 0;
		}
	}

	.slider-nav {
		margin-top: 40px;
		font-size: 0;
	}
	.prev, .next {
		display: inline-block;
		vertical-align: middle;
		height: 50px;
		width: 50px;
		border: 2px solid #fff;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		font-size: 1.25rem;
		line-height: 46px;
		.transition(all .2s);
		&:hover {
			background-color: #fff;
			color: @brand-primary;
		}
	}
	.prev {
		margin-right: 15px;
	}
	.next {
		margin-left: 15px;
	}
	.custom-caption {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		opacity: .5;
		span {
			display: block;
			padding: 3px 2px;
			color: #fff;
			font-family: @ff-headings;
			font-size: 0.88rem;
			font-weight: 400;
			line-height: 1em;
			& + span {
				border-top: 1px solid #fff;
			}
		}
	}
}

#description {
	padding-top: 35px;
	padding-bottom: 80px;
	.btn {
		@media (max-width: @screen-xs-max) {
			display: block;
		}
		& + .btn {
	    	margin-left: 30px;
	    	@media (max-width: @screen-xs-max) {
	    		margin-top: 15px;
		        margin-left: 0;
		    }
	    }
    }
}