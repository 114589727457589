.animated {
    -webkit-animation-duration: 1s;
       -moz-animation-duration: 1s;
         -o-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
       -moz-animation-fill-mode: both;
         -o-animation-fill-mode: both;
            animation-fill-mode: both;
            
    &.infinite {
        -webkit-animation-iteration-count: infinite;
           -moz-animation-iteration-count: infinite;
             -o-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
    }
    
    &.hinge {
        -webkit-animation-duration: 2s;
           -moz-animation-duration: 2s;
             -o-animation-duration: 2s;
                animation-duration: 2s;
    }
}
