@-webkit-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }
}

@-moz-keyframes bounceOutRight {
    0% {
        -moz-transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateX(2000px);
    }
}

@-o-keyframes bounceOutRight {
    0% {
        -o-transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        -o-transform: translateX(-20px);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateX(2000px);
    }
}

@keyframes bounceOutRight {
    0% {
        transform: translateX(0);
    }
    
    20% {
        opacity: 1;
        transform: translateX(-20px);
    }
    
    100% {
        opacity: 0;
        transform: translateX(2000px);
    }
}

.bounceOutRight {
    .animation-name(bounceOutRight);
}