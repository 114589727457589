
.animation(@arguments) {
    -webkit-animation: @arguments;
       -moz-animation: @arguments;
        -ms-animation: @arguments;
         -o-animation: @arguments;
            animation: @arguments;
}

.animation-delay(@delay) {
    -webkit-animation-delay: @delay;
       -moz-animation-delay: @delay;
        -ms-animation-delay: @delay;
         -o-animation-delay: @delay;
            animation-delay: @delay;
}

.animation-duration(@duration) {
    -webkit-animation-duration: @duration;
       -moz-animation-duration: @duration;
        -ms-animation-duration: @duration;
         -o-animation-duration: @duration;
            animation-duration: @duration;
}

.animation-iteration-count(@num) {
    -webkit-animation-iteration-count: @num;
       -moz-animation-iteration-count: @num;
        -ms-animation-iteration-count: @num;
         -o-animation-iteration-count: @num;
            animation-iteration-count: @num;
}

.animation-fill-mode(@mode) {
    -webkit-animation-fill-mode: @mode;
       -moz-animation-fill-mode: @mode;
        -ms-animation-fill-mode: @mode;
         -o-animation-fill-mode: @mode;
            animation-fill-mode: @mode;
}

.animation-name(@name) {
    -webkit-animation-name: @name;
       -moz-animation-name: @name;
        -ms-animation-name: @name;
         -o-animation-name: @name;
            animation-name: @name;
}

.backface-visibility(@visibility) {
    -webkit-backface-visibility: @visibility !important;
       -moz-backface-visibility: @visibility !important;
        -ms-backface-visibility: @visibility !important;
         -o-backface-visibility: @visibility !important;
            backface-visibility: @visibility !important;
}

.animation-timing-function(@name) {
    -webkit-animation-timing-function: @name;
       -moz-animation-timing-function: @name;
        -ms-animation-timing-function: @name;
         -o-animation-timing-function: @name;
            animation-timing-function: @name;
}

.transform-origin(@origin) {
    -webkit-transform-origin: @origin;
       -moz-transform-origin: @origin;
        -ms-transform-origin: @origin;
         -o-transform-origin: @origin;
            transform-origin: @origin;
}


// Transitions
.transition(@transition) {
    -webkit-transition: @transition;
       -moz-transition: @transition;
        -ms-transition: @transition;
         -o-transition: @transition;
            transition: @transition;
}

.transition-transform(@duration, @func) {
    -webkit-transition: -webkit-transform @duration @func;
       -moz-transition: -moz-transform @duration @func;
        -ms-transition: -ms-transform @duration @func;
         -o-transition: -o-transform @duration @func;
            transition: transform @duration @func;
}

.transition-delay(@delay) {
    -webkit-transition-delay: @delay;
       -moz-transition-delay: @delay;
        -ms-transition-delay: @delay;
         -o-transition-delay: @delay;
            transition-delay: @delay;
}

.transition-duration(@duration) {
    -webkit-transition-duration: @duration;
       -moz-transition-duration: @duration;
        -ms-transition-duration: @duration;
         -o-transition-duration: @duration;
            transition-duration: @duration;
}

.transform-rotate(@rotate) {
    -webkit-transform: rotate(@rotate);
       -moz-transform: rotate(@rotate);
        -ms-transform: rotate(@rotate);
         -o-transform: rotate(@rotate);
            transform: rotate(@rotate);
}

.transform-scale(@ratio) {
    -webkit-transform: scale(@ratio);
       -moz-transform: scale(@ratio);
        -ms-transform: scale(@ratio);
         -o-transform: scale(@ratio);
            transform: scale(@ratio);
}

.transform-translateX(@x) {
  -webkit-transform: translateX(@x);
     -moz-transform: translateX(@x);
      -ms-transform: translateX(@x);
       -o-transform: translateX(@x);
          transform: translateX(@x);
}

.transform-translateY(@y) {
  -webkit-transform: translateY(@y);
     -moz-transform: translateY(@y);
      -ms-transform: translateY(@y);
       -o-transform: translateY(@y);
          transform: translateY(@y);
}


