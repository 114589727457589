.container {
	&.md {
		max-width: 960px;
	}
	.md {
		margin-left: auto;
		margin-right: auto;
		max-width: 930px;
	}
}

.v-align {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}

.same-height--md {
	@media (min-width: @screen-md-min) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}

.no-marge {
	margin-left: 0;
	margin-right: 0;
}
.no-gap {
	padding-left: 0;
	padding-right: 0;
}
.bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

// Animate
.animated {
	.animated;
}
.os-animation, .staggered-animation {
	opacity: 0;
	@media (max-width: @screen-xs-max) {
		-webkit-animation-delay: 0s !important;
		-moz-animation-delay: 0s !important;
		-ms-animation-delay: 0s !important;
		-o-animation-delay: 0s !important;
		animation-delay: 0s !important;
	}
}

// Content Body
.content-body {
	position: relative;
	max-width: 900px;
	margin: 0 auto;
	padding-bottom: 90px;

	// All
	& > * {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
	
	// Aside
	& > aside {
		max-width: 900px;
	}

	// Colonnes
	& > .row {
		margin-bottom: @line-height-computed;

		 .col-md-6:first-child {
			padding-left: 0;
			@media (max-width: @screen-sm-max) {
				padding-right: 0;
			}
		}

		 .col-md-6:last-child {
			padding-right: 0;
			@media (max-width: @screen-sm-max) {
				padding-left: 0;
			}
		}
	}
	
	p[style*="text-align: center;"] {
		img {
			display: inline-block;
		}
	}
}

//  Types
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	line-height: normal;
}
h1,.h1 {
	color: #fff;
	@media (max-width: @screen-xs-max) {
		font-size: 1.88rem;
	}
}
h2,.h2 {
	margin-top: 0;
	margin-bottom: 20px;
	font-weight: 600;
	color: @brand-primary;
}
p {
	margin-top: 0;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
}

// Lead
p.lead {
	margin-top: 60px;
	margin-bottom: 60px;
	padding: 0 60px;
	color: @brand-primary;
	font-family: @ff-headings;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 1.5em;
	@media (max-width: @screen-xs-max) {
        padding: 0;
    }
	&:after {
		content : '';
		display: block;
		margin-top: 25px;
		height: 1px;
		width: 40px;
		background-color: #a7a7a7;
	}
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*2;
	margin-bottom: @line-height-computed;
	@media (max-width: @screen-sm-max) {
		padding: 30px;
	}
	@media (max-width: @screen-xs-max) {
		padding: 15px;
	}

	a {
		color: #fff;
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}

// Iframe responsive
.iframe-embed {
	display: block;
	position: relative;
	margin-bottom: @line-height-computed;
	padding: 0;
	padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

	iframe {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

// Boutons
.btn {
	display: inline-block;
    vertical-align: middle;
	padding: 13px 20px;
	border: 2px solid transparent;
	border-radius:5px;
    font-family: @ff-headings;
	font-size: 0.88rem;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.43em;
    text-align: center;
    text-transform: uppercase;
    white-space: normal;
    .transition(all .2s);
    &.btn-primary, &.btn-default, &.btn1 {
    	background-color: @brand-primary;
    	border-color: @brand-primary;
    	color: #fff;
    	&:hover {
			background-color: darken(@brand-primary, 15%);
			border-color: darken(@brand-primary, 15%);
    	}
    }
    &.btn-border, &.btn2 {
    	background-color: transparent;
    	border-color: @brand-primary;
    	color: @brand-primary;
    	&:hover {
    		background-color: @brand-primary;
    		border-color: @brand-primary;
    		color: #fff;
    	}
    }
    &.btn-border-white {
    	padding: 20px 30px;
    	background-color: transparent;
    	border-color: #fff;
    	color: #fff;
    	font-size: 1rem;
    	line-height: 1.25em;
    	&:hover {
			background-color: #fff;
    		color: @brand-primary;
    	}
    }
}

.item {
	&--news {
		position: relative;
		margin: 30px 0;
		.img-wrapper {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			@media (max-width: @screen-sm-max) {
				position: relative;
				top: auto;
				bottom: auto;
				left: auto;
				padding-bottom: 56.25%;
			}
		}
		.content {
			left: 50%;
			padding: 65px 60px 70px 75px;
			background-color: #f3f3f3;
			@media (max-width: @screen-md-max) {
				padding-left: 45px;
				padding-right: 45px;
			}
			@media (max-width: @screen-sm-max) {
				left: auto;
			}
			@media (max-width: @screen-xs-max) {
				padding: 30px 15px;
			}
		}
		.date {
			display: block;
			margin: 0;
			color: #a9a9a9;
			font-family: @ff-headings;
			font-size: 0.75rem;
			font-weight: 400;
			letter-spacing: .75px;
			line-height: 1em;
			text-transform: uppercase;
		}
		h3 {
			margin: 0 0 25px;
			a {
				color: @brand-gray;
			}
		}
		p {
			margin: 0 0 25px;
			color: #979797;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.btn {
			padding-top: 10px;
			padding-bottom: 10px;
		}
		a.more {
			float: right;
			text-decoration: underline;
			@media (max-width: @screen-xs-max) {
				display: block;
				float: none;
				margin-top: 15px;
			}
			.fa {
				margin-left: 5px;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.filter {
	position: relative;
	z-index: 5;
	height: 70px;
	.wrapper {
		.clearfix;
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		width: 100%;
		background-color: #fff;
		border-bottom: 1px solid #e5e5e5;
		> a {
			float: right;
			padding: 0 5px;
			color: #a4a4a4;
			font-family: @ff-headings;
			font-size: 0.94rem;
			font-weight: 600;
			line-height: 1.93em;
			text-decoration: none;
			.fa {
				margin-left: 10px;
			}
			&:hover {
				color: @brand-primary;
			}
		}
	}
	ul {
		display: none;
		margin: 0;
		padding: 35px 0 0;
		list-style: none;
		@media (min-width: @screen-sm-min) {
			-webkit-column-count: 2;
			-moz-column-count: 2;
			column-count: 2;
		}
		@media (min-width: @screen-md-min) {
			-webkit-column-count: 3;
			-moz-column-count: 3;
			column-count: 3;
		}
		@media (min-width: @screen-lg-min) {
			-webkit-column-count: 4;
			-moz-column-count: 4;
			column-count: 4;
		}
		li {
			display: inline-block;
			margin-bottom: 20px;
			width: 100%;
			font-size: 1rem;
			line-height: 1.5em;
			a {
				display: block;
				position: relative;
				padding-left: 15px;
				color: #858585;
				text-decoration: none;
				&:before {
					content: '\f105';
					position: absolute;
					top: 0;
					left: 0;
					font-family: 'FontAwesome';
				}
			}
			&.active, &:hover {
				a {
					color: @brand-primary;
				}
			}
		}
	}
}

.view-filters, .filtre_date {
	padding: 20px 0;
	border-bottom: 1px solid #e5e5e5;
	form {
		text-align: right;
		@media (max-width: @screen-xs-max) {
			text-align: left;
		}
	}
	.views-exposed-widget, form > div > .form-group {
		font-size: 0;
		label, .views-widget, > .form-control {
			display: inline-block;
	    	vertical-align: middle;
	    	@media (max-width: @screen-xs-max) {
				display: block;
			}
		}
	}
	label {
	    margin: 0;
	    margin-right: 5px;
	    color: #a4a4a4;
	    font-family: @ff-headings;
	    font-size: 0.94rem;
	    font-weight: 500;
	    line-height: normal;
	}
	.form-control {
		margin: 0;
		padding: 0 25px;
    	height: auto;
	    width: auto;
	    background-color: transparent;
	    border: 0;
	    border-radius: 0;
	    -webkit-box-shadow: none;
	    -moz-box-shadow: none;
	    box-shadow: none;
	    @media (max-width: @screen-xs-max) {
			padding: 10px 0;
		}
	}
	.form-control, output {
		display: block;
	    color: #000;
	    font-family: @ff-headings;
	    font-size: 0.94rem;
	    font-weight: 500;
	    line-height: normal;
	}
	::-webkit-input-placeholder {
	  color: #000;
	}
	::-moz-placeholder {
	  color: #000;
	}
	:-ms-input-placeholder {
	  color: #000;
	}
	:-moz-placeholder {
	  color: #000;
	}

	.container-inline-date .form-item .form-item {
		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}
}

.filtre_date {
	form {
		> div {
			font-size: 0;
			> .form-group {
				display: inline-block;
				vertical-align: middle;
				margin: 0 15px 0 0;
			}
		}
	}
}

.node-type-webform, .page-node-done {
	#main {
		padding-top: 40px;
		padding-bottom: 80px;
		max-width: 960px;
	}
}

.page-node-done {
	#main {
		.links {
			display: none;
		}
	}
}

.page-actualites {
	#main {
		padding-top: 40px;
		padding-bottom: 80px;
	}
}
.node-details {
	padding-top: 40px;
	padding-bottom: 80px;
	.img-wrapper {
		img {
			float: left;
			margin: 0 30px 30px 0;
			@media (max-width: @screen-xs-max) {
				float: none;
				margin: 0 0 30px;
			}
		}
	}
	.date {
		display: block;
		margin: 0;
		color: #a9a9a9;
		font-family: @ff-headings;
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: .75px;
		line-height: 1em;
		text-transform: uppercase;
	}
}


// Paragraphs
.paragraphs-items > div {
	margin-top: 75px;
	margin-bottom: 75px;
}
.content-evidence {
	position: relative;
	padding-top: 60px;
	padding-bottom: 90px;
	background-color: #f1f1f1;
	@media (max-width: @screen-xs-max) {
		padding-bottom: 0;
	}
	.md {
		padding-left: 15px;
		padding-right: 15px;
	}
	.imgWrapper {
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 66.66666667%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media (max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			margin-top: 60px;
			padding-bottom: 56.25%;
			height: 0;
			width: 100%;
		}
	}
	.content {
		position: relative;
		z-index: 5;
	}
	*:first-child {
		margin-top: 0;
	}
}