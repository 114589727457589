// FAQ
body.page-faq {
	#faq {
		padding-top: 40px;
		padding-bottom: 150px;
	}
	.panel {
		border-bottom: 1px solid #e5e5e5;
	}
	.panel-title {
		position: relative;
		margin: 0;
		padding: 17px 0;
		padding-right: 30px;
		color: @brand-primary;
		font-size: 1.5rem;
		font-weight: 600;
		line-height: normal;
		a {
			text-decoration: none;
			&:not(.collapsed) {
				.fa {
					&:before {
						content: '\f068';
					}
				}
			}
		}
		.fa {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -10px;
			font-size: 1.25rem;
			line-height: 1em;
		}
	}
	.panel-body {
		padding-bottom: 30px;
		p {
			margin: 0 0 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}