@-webkit-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1);
    }
    
    25% {
        -webkit-transform: scale(.95);
    }
    
    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: scale(.3);
    }    
}

@-moz-keyframes bounceOut {
    0% {
        -moz-transform: scale(1);
    }
    
    25% {
        -moz-transform: scale(.95);
    }
    
    50% {
        opacity: 1;
        -moz-transform: scale(1.1);
    }
    
    100% {
        opacity: 0;
        -moz-transform: scale(.3);
    }    
}

@-o-keyframes bounceOut {
    0% {
        -o-transform: scale(1);
    }
    
    25% {
        -o-transform: scale(.95);
    }
    
    50% {
        opacity: 1;
        -o-transform: scale(1.1);
    }
    
    100% {
        opacity: 0;
        -o-transform: scale(.3);
    }    
}

@keyframes bounceOut {
    0% {
        transform: scale(1);
    }
    
    25% {
        transform: scale(.95);
    }
    
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    
    100% {
        opacity: 0;
        transform: scale(.3);
    }    
}

.bounceOut {
    .animation-name(bounceOut);
}