@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-20px);
    }
    
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -o-transform: translateX(-20px);
    }
    
    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInLeft {
    .animation-name(fadeInLeft);
}