@-webkit-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
    }
    
    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
    }
}

@-moz-keyframes bounceOutDown {
    0% {
        -moz-transform: translateY(0);
    }
    
    20% {
        opacity: 1;
        -moz-transform: translateY(-20px);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateY(2000px);
    }
}

@-o-keyframes bounceOutDown {
    0% {
        -o-transform: translateY(0);
    }
    
    20% {
        opacity: 1;
        -o-transform: translateY(-20px);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateY(2000px);
    }
}

@keyframes bounceOutDown {
    0% {
        transform: translateY(0);
    }
    
    20% {
        opacity: 1;
        transform: translateY(-20px);
    }
    
    100% {
        opacity: 0;
        transform: translateY(2000px);
    }
}

.bounceOutDown {
    .animation-name(bounceOutDown);
}