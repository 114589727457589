@-webkit-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
    }
}

@-moz-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateY(2000px);
    }
}

@-o-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -o-transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateY(2000px);
    }
}

@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        transform: translateY(2000px);
    }
}

.fadeOutDownBig {
    .animation-name(fadeOutDownBig);
}