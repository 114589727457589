@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -moz-transform: translateX(2000px);
    }
    
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -o-transform: translateX(2000px);
    }
    
    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }
    
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRightBig {
    .animation-name(fadeInRightBig);
}