@-webkit-keyframes lightSpeedOut {
    0% { -webkit-transform: translateX(0%) skewX(0deg); opacity: 1; }
    100% { -webkit-transform: translateX(100%) skewX(-30deg); opacity: 0; }
}

@-moz-keyframes lightSpeedOut {
    0% { -moz-transform: translateX(0%) skewX(0deg); opacity: 1; }
    100% { -moz-transform: translateX(100%) skewX(-30deg); opacity: 0; }
}

@-o-keyframes lightSpeedOut {
    0% { -o-transform: translateX(0%) skewX(0deg); opacity: 1; }
    100% { -o-transform: translateX(100%) skewX(-30deg); opacity: 0; }
}

@keyframes lightSpeedOut {
    0% { transform: translateX(0%) skewX(0deg); opacity: 1; }
    100% { transform: translateX(100%) skewX(-30deg); opacity: 0; }
}

.lightSpeedOut {
    .animation-name(lightSpeedOut);
    .animation-timing-function(ease-in);
}