// Home
#leaderboard {
	position: relative;
	z-index: 0;
	.cycle-slideshow {
		position: relative;
		height: 685px;
		width: 100%;
		@media (max-width: @screen-xs-max) {
	        height: 480px;
	    }
		.slide {
			height: 685px;
			width: 100%;
			background-color: #000;
			@media (max-width: @screen-xs-max) {
		        height: 480px;
		    }
			.bg {
				opacity: .46;
			}
			.content {
				.v-align;
				z-index: 5;
				width: 100%;
			}
			h2 {
				margin: 0 0 30px;
				color: #fff;
				font-size: 3rem;
				font-weight: 700;
				@media (max-width: @screen-xs-max) {
					font-size: 1.88rem;
				}
			}
			.btn {
				min-width: 145px;
				& + .btn {
					margin-left: 15px;
					@media (max-width: @screen-xs-max) {
						margin-top: 15px;
						margin-left: 0;
					}
				}
			}
		}
		.cycle-pager {
			position: absolute;
			z-index: 110;
			bottom: 35px;
			right: 155px;
			@media (max-width: @screen-xs-max) {
				bottom: 15px;
				right: 15px;
			}
			.dot {
				display: block;
				float: left;
				border: 10px solid transparent;
				border-radius: 50%;
				cursor: pointer;
				.transition(all .2s);
				span {
					display: block;
					height: 5px;
					width: 5px;
					background-color: #fff;
					border-radius: 50%;
					opacity: .2;
					.transition(all .2s);
				}
				& + .dot {
					margin-left: 5px;
				}
				&.cycle-pager-active, &:hover {
					border-color: fade(#fff, 5%);
					span {
						opacity: 1;
					}
				}
			}
		}
	}
}

.main-wrapper {
	position: relative;
}

#about {
	position: relative;
	z-index: 1;
	padding-bottom: 115px;
	background-color: #f3f3f3;
	@media (max-width: @screen-md-max) {
		padding-top: 60px;
        background-color: #fff;
    }
    @media (max-width: @screen-xs-max) {
        padding-bottom: 60px;
    }
	.wrapper {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: -100px;
			right: 50%;
			bottom: 0;
			left: 0;
			background-color: #fff;
			box-shadow: 0px 36px 65px 0px rgba(0, 0, 0, 0.17);
			@media (max-width: @screen-md-max) {
		        display: none;
		    }
		}
	}
	.content {
		position: relative;
		z-index: 1;
		padding-bottom: 115px;
		color: #979797;
		@media (max-width: @screen-md-max) {
	        padding-bottom: 0;
	    }
		.content-wrapper {
			margin-top: -10px;
			// padding-right: 0;
			@media (max-width: @screen-md-max) {
		        margin-top: 0;
		        // padding-right: 15px;
		    }
		}
	}
	h2 {
		margin: 0 0 45px;
		color: @brand-gray;
		strong {
			color: @brand-primary;
			font-weight: 600;
		}
	}
	p {
		// margin: 0 0 45px;
		margin: 0 0 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.btn {
		padding: 15px 30px;
	}
	img {
		height: auto !important;
		width: 100%;
	}
}

#map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	@media (max-width: @screen-md-max) {
        position: relative;
        top: auto;
        left: auto;
        background-color: #f3f3f3;
    }
	.map-wrapper {
		position: relative;
		z-index: 5;
		left: 50%;
		padding-top: 95px;
		padding-left: 45px;
		width: 50%;
		@media (max-width: @screen-md-max) {
			left: auto;
			padding-bottom: 60px;
			width: 100%;
		}
		@media (max-width: @screen-xs-max) {
			padding-top: 60px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.intro {
		position: relative;
		z-index: 1;
		margin-bottom: -35px;
		margin-left: 45px;
		@media (max-width: @screen-xs-max) {
			margin-bottom: 35px;
			margin-left: 0;
		}
		h1 {
			.h2;
			margin: 0 0 10px;
		}
		p {
			max-width: 420px;
			color: #737373;
			font-family: @ff-headings;
			font-weight: 600;
			line-height: normal;
			@media (max-width: @screen-xs-max) {
				display: none;
			}
		}
	}
	.bubble {
		display: none;
		position: absolute;
		z-index: 0;
		bottom: 100%;
		left: 50%;
		margin-bottom: -15px;
		padding: 0 12px 40px;
		@media (min-width: @screen-sm-min) {
			-webkit-transform: translate(-50%,0);
			-moz-transform: translate(-50%,0);
			-ms-transform: translate(-50%,0);
			-o-transform: translate(-50%,0);
			transform: translate(-50%,0);
			background-image: url(../img/bubble.png);
		}
		@media (max-width: @screen-xs-max) {
			display: block;
			position: relative;
			margin-bottom: 1px;
			padding: 0;
			bottom: auto;
			left: auto;
		}
		&.open {
			display: block;
			z-index: 4;
		}
		.bubble-wrapper {
			display: block;
			position: relative;
			margin: 10px;
			height: 140px;
			width: 140px;
			background-color: @brand-primary;
			border-radius: 50%;
			color: #fff;
			font-family: @ff-headings;
			font-weight: 600;
			line-height: 1.25em;
			text-align: center;
			overflow: hidden;
			@media (max-width: @screen-xs-max) {
				margin: 0;
				padding: 15px;
				height: auto;
				width: 100%;
				border-radius: 0;
			}
			strong {
				text-transform: uppercase;
			}
			.content {
				@media (min-width: @screen-sm-min) {
					.v-align;
				}
				z-index: 1;
				width: 100%;
				@media (max-width: @screen-xs-max) {
					position: relative;
				}
			}
			.bg {
				opacity: .3;
			}
		}
	}
	.map {
		position: relative;
		.img-wrapper {
			overflow: hidden;
			@media (max-width: @screen-xs-max) {
		        display: none;
		    }
			img {
				max-width: none;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				position: absolute;
				@media (max-width: @screen-xs-max) {
					position: relative;
				}
				a:not(.bubble) {
					display: block;
					position: relative;
					z-index: 1;
					height: 42px;
					width: 26px;
					background-image: url(../img/snippet.png);
					text-decoration: none;
					.transition(all .2s);
					span {
						display: block;
						color: #fff;
						text-align: center;
					}
					&.open {
						z-index: 5;
					}
					&:hover, &.open {
						background-image: url(../img/snippet-hover.png);
					}
					@media (max-width: @screen-xs-max) {
						display: none;
					}
				}
				&.disbl {
					a:not(.bubble) {
						z-index: 0;
						background-image: url(../img/snippet-hover.png);
					}
				}
				@media (min-width: @screen-sm-min) {
					&.area-1 {
						bottom: 248px;
						left: 0;
					}
					&.area-2 {
						bottom: 233px;
						left: 25px;
					}
					&.area-3 {
						bottom: 263px;
						left: 50px;
					}
					&.area-4 {
						bottom: 194px;
						left: 70px;
					}
					&.area-5 {
						bottom: 318px;
						left: 95px;
					}
					&.area-6 {
						bottom: 403px;
						left: 95px;
					}
					&.area-7 {
						bottom: 198px;
						left: 115px;
					}
					&.area-8 {
						bottom: 90px;
						left: 156px;
					}
					&.area-9 {
						bottom: 136px;
						left: 156px;
					}
					&.area-10 {
						bottom: 246px;
						left: 200px;
					}
					&.area-11 {
						bottom: 126px;
						left: 220px;
					}
					&.area-12 {
						bottom: 281px;
						left: 230px;
					}
					&.area-13 {
						bottom: 186px;
						left: 235px;
					}
					&.area-14 {
						bottom: 306px;
						left: 280px;
					}
					&.area-15 {
						bottom: 238px;
						left: 335px;
					}
					&.area-16 {
						bottom: 301px;
						left: 345px;
					}
					&.area-17 {
						bottom: 388px;
						left: 370px;
					}
					&.area-18 {
						bottom: 429px;
						left: 485px;
					}
				}
			}
		}
	}
}

#actualites {
	padding-top: 65px;
	padding-bottom: 45px;
	@media (max-width: @screen-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	h2 {
		margin: 0 0 10px;
		text-align: center;
		color: @brand-gray;
		strong {
			color: @brand-primary;
			font-weight: 600;
		}
	}
}