@-webkit-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(200deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOut {
    0% {
        -moz-transform-origin: center center;
        -moz-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -moz-transform-origin: center center;
        -moz-transform: rotate(200deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOut {
    0% {
        -o-transform-origin: center center;
        -o-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -o-transform-origin: center center;
        -o-transform: rotate(200deg);
        opacity: 0;
    }
}

@keyframes rotateOut {
    0% {
        transform-origin: center center;
        transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        transform-origin: center center;
        transform: rotate(200deg);
        opacity: 0;
    }
}

.rotateOut {
    .animation-name(rotateOut);
}