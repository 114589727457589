@-webkit-keyframes flash {
    0%, 50%, 100% {opacity: 1;}    
    25%, 75% {opacity: 0;}
}

@-moz-keyframes flash {
    0%, 50%, 100% {opacity: 1;}    
    25%, 75% {opacity: 0;}
}

@-o-keyframes flash {
    0%, 50%, 100% {opacity: 1;}    
    25%, 75% {opacity: 0;}
}

@keyframes flash {
    0%, 50%, 100% {opacity: 1;}    
    25%, 75% {opacity: 0;}
}

.flash {
    .animation-name(flash);
}