@-webkit-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(-200deg);
        opacity: 0;
    }
    
    100% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}
@-moz-keyframes rotateIn {
    0% {
        -moz-transform-origin: center center;
        -moz-transform: rotate(-200deg);
        opacity: 0;
    }
    
    100% {
        -moz-transform-origin: center center;
        -moz-transform: rotate(0);
        opacity: 1;
    }
}
@-o-keyframes rotateIn {
    0% {
        -o-transform-origin: center center;
        -o-transform: rotate(-200deg);
        opacity: 0;
    }
    
    100% {
        -o-transform-origin: center center;
        -o-transform: rotate(0);
        opacity: 1;
    }
}
@keyframes rotateIn {
    0% {
        transform-origin: center center;
        transform: rotate(-200deg);
        opacity: 0;
    }
    
    100% {
        transform-origin: center center;
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateIn {
    .animation-name(rotateIn);
}