// FAQ
body[class*="page-extranet"] {
	#banner {
		height: 278px;
		// background-color: #e3e3e3;
		&:after {
			display: none;
		}
		h1 {
			// color: @brand-primary;
		}
	}
	#faq-theme {
		display: none;
	}
	#extranet {
		padding-top: 35px;
		padding-bottom: 150px;
	}
	.extranet-wrapper {
		padding-top: 35px;
		padding-bottom: 70px;
	}
	.panel {
		border-bottom: 1px solid #e5e5e5;
	}
	.panel-title {
		.clearfix;
		position: relative;
		margin: 0;
		padding: 17px 0;
		padding-right: 50px;
		> a {
			color: #626262;
			font-family: @ff-headings;
			font-size: 1.5rem;
			font-weight: 600;
			line-height: normal;
			text-decoration: none;
			&:hover {
				color: @brand-primary;
			}
			&:not(.collapsed) {
				color: @brand-primary;
				.fa {
					&:before {
						content: '\f068';
					}
				}
			}
		}
		.fa {
			position: absolute;
			top: 50%;
			right: 15px;
			margin-top: -10px;
			color: @brand-primary;
			font-size: 1.25rem;
			line-height: 1em;
		}
	}
	.files {
		font-size: 0; 
		@media (max-width: @screen-sm-max) {
			float: none;
		}
		&:not(:only-child) {
			margin-bottom: 30px;
		}
		.file {
			display: list-item;
			list-style: none;
			vertical-align: middle;
			padding: 0 0 10px 5px;
			margin-bottom: 10px;
			color: #626262;
			font-family: @ff-headings;
			font-size: 0.88rem;
			font-weight: 600;
			line-height: normal;
			text-decoration: none;
			@media (max-width: @screen-xs-max) {
				display: block;
			}
			&:before {
				content: '\f1c1';
				margin-right: 5px;
				font-family: 'FontAwesome';
			}
			&:hover {
				color: @brand-primary;
				text-decoration: underline;
			}
			& + .file {
				margin-left: -1px;
				@media (max-width: @screen-xs-max) {
					margin-top: -1px;
					margin-left: 0;
				}
			}
			&:first-child {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				@media (max-width: @screen-xs-max) {
					border-top-right-radius: 5px !important;
					border-bottom-left-radius: 0;
				}
			}
			&:last-child {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				@media (max-width: @screen-xs-max) {
					border-top-right-radius: 0;
					border-bottom-left-radius: 5px;
				}
			}
		}
	}
	.ext-content {
		clear: both;
	}
	.panel-body {
		.clearfix;
		padding-bottom: 50px;
		p {
			margin: 0 0 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

body.page-extranet {
	#main {
		padding-top: 40px;
		padding-bottom: 80px;
		.btn {
			@media (max-width: @screen-sm-max) {
				padding-left: 10px;
				padding-right: 10px;
			}
			@media (max-width: @screen-xs-max) {
				margin-bottom: 30px;
			}
		}
	}
}