@-webkit-keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOutDownRight {
    0% {
        -moz-transform-origin: right bottom;
        -moz-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -moz-transform-origin: right bottom;
        -moz-transform: rotate(-90deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOutDownRight {
    0% {
        -o-transform-origin: right bottom;
        -o-transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        -o-transform-origin: right bottom;
        -o-transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownRight {
    0% {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1;
    }
    
    100% {
        transform-origin: right bottom;
        transform: rotate(-90deg);
        opacity: 0;
    }
}

.rotateOutDownRight {
    .animation-name(rotateOutDownRight);
}