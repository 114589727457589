@-webkit-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }
    
    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
    }
    
    80% {
        -webkit-transform: translateX(10px);
    }
    
    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes bounceInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(2000px);
    }
    
    60% {
        opacity: 1;
        -moz-transform: translateX(-30px);
    }
    
    80% {
        -moz-transform: translateX(10px);
    }
    
    100% {
        -moz-transform: translateX(0);
    }
}

@-o-keyframes bounceInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(2000px);
    }
    
    60% {
        opacity: 1;
        -o-transform: translateX(-30px);
    }
    
    80% {
        -o-transform: translateX(10px);
    }
    
    100% {
        -o-transform: translateX(0);
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }
    
    60% {
        opacity: 1;
        transform: translateX(-30px);
    }
    
    80% {
        transform: translateX(10px);
    }
    
    100% {
        transform: translateX(0);
    }
}

.bounceInRight {
    .animation-name(bounceInRight);
}